// import UserData, { StorageType } from '@/components/leasing/Showroom/src/components/providers/UserData';
import VueRouter, { RouteConfig } from 'vue-router';

import About from '../views/about/about';
// import CarCalc from '@/components/leasing/Showroom/src/components/carcalc';
// import CarDetail from '@/components/leasing/Showroom/src/components/cardetail';
// import CarList from '@/components/leasing/Showroom/src/components/carlist';
import Confirmation from '../views/confirmation/confirmation';
import Confirmation_finished from '../views/confirmation/confirmation_finished';
import Contact from '../views/contact/contact';
import CustomRouteHandler from '../views/CustomRouteHandler/CustomRouteHandler';
// import ErhvervCars from '../views/erhverv_cars/erhverv_cars';
import ErhvervFAQ from '../views/erhverv_faq/erhverv_faq';
import Jobs from '../views/jobs/jobs';
import JobsSingle from '../views/jobs/jobs_single';
// App views
import LandingPage from '../views/landingpage/landingPage';
// LeasingPro
import LeasingPro from '@/components/leasingpro/App.vue';
// import LikedCars from '../views/liked_cars/liked_cars';
import Logins from '../views/logins/logins';
import News from '../views/news/news';
// import PrivatCars from '../views/privat_cars/privat_cars';
// import PrivatFAQ from '../views/privat_faq/privat_faq';
// import PrivatLeasing from '../views/privat_leasing/privat_leasing';
// import PrivatReleasingShowroom from '@/components/leasing/privat/releasing';
import ProPlus from '@/components/leasing/proplus/proplus';
import ReferalLanding from '../views/referal_landing/ReferalLanding';
// import Upload from '@/components/leasing/Showroom/src/components/upload';
import Vue from 'vue';
import eventBus from '../eventBus';
// ShowRoom
// import store from '@/components/leasing/Showroom/src/store';
import BecomeCustomer from '@/views/become_customer/BecomeCustomer.vue';

Vue.use(VueRouter);

// const showroomRoutingPrivat = [
//   {
//     path: '/',
//     name: 'CarListPrivat',
//     component: CarList,
//   },
//   {
//     path: 'upload/:token',
//     name: 'UploadPrivat',
//     component: Upload
//   },
//   {
//     path: ':vehicleId',
//     name: 'CarCalcPrivat',
//     component: CarCalc,
//   }
// ]
// const showroomRoutingErhverv = [
//   {
//     path: '/',
//     name: 'CarListErhverv',
//     component: CarList,
//   },
//   {
//     path: 'upload/:token',
//     name: 'UploadErhverv',
//     component: Upload
//   },
//   {
//     path: ':vehicleId',
//     name: 'CarCalcErhverv',
//     component: CarCalc,
//   }
// ]

const routes: RouteConfig[] = [

  // Wrapper start
  // {
  //   path: '/',
  //   name: 'home',
  //   component: LandingPage,
  // },
  {
    path: '/',
    name: 'home',
    component: CustomRouteHandler,
  },
  {
    path: '/kontakt',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/bliv-kunde',
    name: 'bliv-kunde',
    component: BecomeCustomer,
  },
  {
    path: '/om',
    name: 'about',
    component: About,
  },
  {
    path: '/log-paa',
    name: 'logins',
    component: Logins
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: Confirmation,
    props: true
  },
  {
    path: '/confirmation_finished',
    name: 'confirmation_finished',
    component: Confirmation_finished,
    props: true
  },

  // 404
  {
    path: '*',
    name: 'CustomRouteHandler',
    component: CustomRouteHandler,
  },

  //News
  {
    path: '/nyheder',
    name: 'News',
    component: News,
  },
  {
    path: '/nyheder/:article',
    name: 'News Single',
    // component: NewsSingle,
    component: CustomRouteHandler,
  },


  //Referal landing
  {
    path: '/secret',
    name: 'ReferalLanding',
    component: ReferalLanding,
    meta: {
      noindex: true
    }
  },

  //Jobs
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs,
  },
  {
    path: '/jobs/:job',
    name: 'Jobs Single',
    component: JobsSingle,
  },

  // Leasing
  // {
  //   path: '/privat',
  //   name: 'PrivatLeasing',
  //   component: PrivatLeasing,
  // },
  // {
  //   path: '/privat/faq',
  //   name: 'PrivatFAQ',
  //   component: PrivatFAQ,
  // },
  // {
  //   path: '/privat/showroom',
  //   name: 'PrivatCars',
  //   component: PrivatCars,
  //   children: showroomRoutingPrivat
  // },
  // {
  //   path: '/privat/liked',
  //   name: 'LikedCarsPrivat',
  //   component: LikedCars,
  // },
  {
    path: '/erhverv',
    name: 'ErhvervLeasing',
    // component: ErhvervsLeasing,
    component: CustomRouteHandler,
  },
  {
    path: '/erhverv/faq',
    name: 'ErhvervFAQ',
    component: ErhvervFAQ,
  },
  // {
  //   path: '/erhverv/showroom',
  //   name: 'ErhvervCars',
  //   component: ErhvervCars,
  //   children: showroomRoutingErhverv
  // },
  // {
  //   path: '/erhverv/liked',
  //   name: 'LikedCarsErhverv',
  //   component: LikedCars,
  // },
  // {
  //   path: '/erhverv/showroom',
  //   name: 'ErhvervCars',
  //   component: ErhvervCars,
  //   children: showroomRoutingErhverv
  // },

  // Leasing Pro
  {
    path: '/leasingpro',
    name: 'leasingpro',
    component: LeasingPro,
  },

  // Leasing Pro+
  {
    path: '/leasing/fleet-management/leasing-pro-plus',
    name: 'leasingproplus',
    component: ProPlus,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {   // Jump to anchor with scroll animation
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    }
    return { x: 0, y: 0 }
  }
});

export default router;
