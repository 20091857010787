<template>
  <div>
    <div class="main page-logins">


      <section class="section  login-section">
        <div class="wrap">
          <div class="sides flex flex-s3">
            <div class="left-side">
              <svg width="286" height="412" viewBox="0 0 286 412" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="142.5" cy="234.5" r="142.5" fill="#E1F2F0"/>
                <path d="M150.974 213.5L150.973 34.8968L177.168 8.81093M202.078 215L202.078 185.807L226.755 160.547L202.946 135.514L227.132 110.465L203.622 85.5391L227.913 60.5546L177.168 8.81093M177.168 8.81093L176.166 70.7292M176.099 97.3707L176.099 210" stroke="#1F4743" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M259.35 279.055C270.918 326.155 242.115 373.715 195.015 385.284C147.915 396.853 100.355 368.049 88.7859 320.949C77.2171 273.849 106.021 226.289 153.121 214.72C200.22 203.151 247.781 231.955 259.35 279.055Z" stroke="#1F4743" stroke-width="8"/>
                <path d="M204.236 325.878C204.236 342.029 191.143 355.122 174.992 355.122C158.841 355.122 145.748 342.029 145.748 325.878C145.748 309.727 158.841 296.634 174.992 296.634C191.143 296.634 204.236 309.727 204.236 325.878Z" stroke="#1F4743" stroke-width="8"/>
              </svg>
            </div>
            <div class="content">

              <h1>{{title}}</h1>

              <div class="card-style links-list" v-if="currentView=='buttons-view'">
                <div class="buttons">
                  <div class="item link-button-item with-info" v-for="link in links" :key="'login-link-'+link.title" >
                    <router-link class="" :to="link.url" v-if="!link.external" >
                      <span class="info-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24 12C24 18.6171 18.617 24 12 24C5.38298 24 0 18.617 0 12C0 5.38298 5.38298 0 12 0C18.617 0 24 5.38273 24 12ZM21.9427 12C21.9427 6.51416 17.4855 2.05714 11.9999 2.05714C6.51404 2.05714 2.05702 6.51435 2.05702 12C2.05702 17.4858 6.51422 21.9429 11.9999 21.9429C17.4857 21.9771 21.9427 17.4857 21.9427 12Z" fill="#69BDB6"/>
                        <path d="M12.0013 10.2515C11.4185 10.2515 10.9727 10.6973 10.9727 11.2801V16.5258C10.9727 17.1086 11.4184 17.5544 12.0013 17.5544C12.5841 17.5544 13.0299 17.1087 13.0299 16.5258V11.2801C13.0299 10.6973 12.5841 10.2515 12.0013 10.2515Z" fill="#69BDB6"/>
                        <path d="M12.0013 6.47999C11.727 6.47999 11.4527 6.58283 11.2812 6.7885C11.0755 6.95998 10.9727 7.23429 10.9727 7.50838C10.9727 7.57702 10.9727 7.64542 11.0069 7.71406C11.0069 7.7827 11.0411 7.8511 11.0755 7.91974C11.1097 7.98837 11.1441 8.02257 11.1783 8.09121C11.2125 8.15985 11.247 8.19405 11.3154 8.26269C11.6925 8.63985 12.3782 8.63985 12.7554 8.26269C12.7896 8.22849 12.8582 8.15985 12.8924 8.09121C12.9266 8.02257 12.961 7.98837 12.9952 7.91974C13.0294 7.8511 13.0294 7.7827 13.0639 7.71406C13.0639 7.64542 13.0981 7.57702 13.0981 7.50838C13.0981 7.23406 12.9952 6.95975 12.7896 6.78826C12.5499 6.58282 12.2756 6.47998 12.0013 6.47998L12.0013 6.47999Z" fill="#69BDB6"/>
                        </svg>
                        <span class="info-text">{{link.info}}</span>
                      </span>

                      <span class="label">
                        {{link.title}}
                      </span><span class="icon">
                        <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.4766 1L30.4766 7L24.4766 13" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M30 7H2" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                    </router-link>
                    <a class="" :href="link.url" v-if="link.external" target="_blank">
                      <span class="info-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24 12C24 18.6171 18.617 24 12 24C5.38298 24 0 18.617 0 12C0 5.38298 5.38298 0 12 0C18.617 0 24 5.38273 24 12ZM21.9427 12C21.9427 6.51416 17.4855 2.05714 11.9999 2.05714C6.51404 2.05714 2.05702 6.51435 2.05702 12C2.05702 17.4858 6.51422 21.9429 11.9999 21.9429C17.4857 21.9771 21.9427 17.4857 21.9427 12Z" fill="#69BDB6"/>
                        <path d="M12.0013 10.2515C11.4185 10.2515 10.9727 10.6973 10.9727 11.2801V16.5258C10.9727 17.1086 11.4184 17.5544 12.0013 17.5544C12.5841 17.5544 13.0299 17.1087 13.0299 16.5258V11.2801C13.0299 10.6973 12.5841 10.2515 12.0013 10.2515Z" fill="#69BDB6"/>
                        <path d="M12.0013 6.47999C11.727 6.47999 11.4527 6.58283 11.2812 6.7885C11.0755 6.95998 10.9727 7.23429 10.9727 7.50838C10.9727 7.57702 10.9727 7.64542 11.0069 7.71406C11.0069 7.7827 11.0411 7.8511 11.0755 7.91974C11.1097 7.98837 11.1441 8.02257 11.1783 8.09121C11.2125 8.15985 11.247 8.19405 11.3154 8.26269C11.6925 8.63985 12.3782 8.63985 12.7554 8.26269C12.7896 8.22849 12.8582 8.15985 12.8924 8.09121C12.9266 8.02257 12.961 7.98837 12.9952 7.91974C13.0294 7.8511 13.0294 7.7827 13.0639 7.71406C13.0639 7.64542 13.0981 7.57702 13.0981 7.50838C13.0981 7.23406 12.9952 6.95975 12.7896 6.78826C12.5499 6.58282 12.2756 6.47998 12.0013 6.47998L12.0013 6.47999Z" fill="#69BDB6"/>
                        </svg>
                        <span class="info-text">{{link.info}}</span>
                      </span>

                      <span class="label">
                        {{link.title}}
                      </span><span class="icon">
                        <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.4766 1L30.4766 7L24.4766 13" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M30 7H2" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>

              <div class="card-style login-box" v-if="currentView=='leasingpro-login'">
                <div class="title-wrap">
                  <a class="back-arrow" :href="'/log-paa'">
                    <span class="back-arrow-icon">
                      <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.52344 13L1.52344 7L7.52344 1" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 7L30 7" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </span>
                  </a>
                  <h2 class="h3-1 title">Leasing Pro</h2>
                </div>

                <div class="form-wrap">
                  <form method="POST" action="" v-on:submit="onSubmit" ref="loginForm">
                    <div class="login-field">
                      <InputField :placeholder="'Brugernavn'" :name="'username'" :value="''" type="text" theme="nr1" @focus="onFocusUsername" />
                      <span class="error-label" v-if="userNameErrLabel">{{userNameErrLabel}}</span>
                    </div>
                    <div class="login-field">
                      <InputField :placeholder="'Kodeord'" :name="'password'" type="password"  theme="nr1" @focus="onFocusPassword" />
                      <span class="error-label" v-if="passwordErrLabel">{{passwordErrLabel}}</span>
                    </div>

                    <input type="submit" name="submit" value="Log ind" class="button" />
                  </form>
                </div>

                <p class="foot-info">Har du glemt kodeordet?  Kontakt os på tlf. 70 60 70 10.</p>

              </div>

              <div class="card-style login-box" v-if="currentView=='forhandler-login'">
                <div class="title-wrap">

                  <a class="back-arrow" :href="'/log-paa'">
                    <span class="back-arrow-icon">
                      <svg width="32" height="14" viewBox="0 0 32 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.52344 13L1.52344 7L7.52344 1" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 7L30 7" stroke="#1F4743" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </span>
                  </a>
                  <h2 class="h3-1 title">Forhandler</h2>
                </div>

                <div class="form-wrap">
                  <form method="POST" action="" v-on:submit="onSubmit" ref="loginForm">

                    <!--'theme', 'size', 'name', 'value', 'placeholder', 'type', 'customClass'-->
                    <div class="login-field">
                      <InputField :placeholder="'Brugernavn'" :name="'username'" :value="''" type="text" theme="nr1" @focus="onFocusUsername" />
                      <span class="error-label" v-if="userNameErrLabel">{{userNameErrLabel}}</span>
                    </div>
                    <div class="login-field">
                      <InputField :placeholder="'Kodeord'" :name="'password'" type="password"  theme="nr1" @focus="onFocusPassword" />
                      <span class="error-label" v-if="passwordErrLabel">{{passwordErrLabel}}</span>
                    </div>

                    <input type="submit" name="submit" value="Log på" class="button" />
                  </form>
                </div>

                <p class="foot-info">Har du glemt kodeordet?  Kontakt os på tlf. 70 60 70 10.</p>

              </div>
            </div>
          </div>
        </div>
      </section>

    </div>

  </div>
</template>
<script>

import InputField from "@/components/ui/input-field/InputField.vue";

export default {
  props: ['cmsGlobalData', 'cmsPageData', 'view'],
  components: {
    'InputField': InputField,
  },

  data() {
    return {
      thisSlug: "",
      title: "Login",
      rootView: 'buttons-view',
      currentView: 'buttons-view',
      // currentView: 'leasingpro-login',

      userName: '',
      password: '',

      userNameErrLabel: "",
      passwordErrLabel: "",

      links: [
        {
        "url": "/leasingpro",
        "title": "Leasing Pro",
        "info" : "Flådeadministration for erhvervskunder. \nTræk rapporter, se forbrug, m.m."
      },{
        "url": this.getFleetUrl(),
        "external" : true,
        "title": "Leasing Pro+",
        "info" : "Flådemodul til erhvervskunder. Showroom for bilbrugere og Fleet Managers."
      },{
        "url": "https://app.uniify.io/consent/a82gvdf3sf/aq9d3iiux0?cc",
        "external" : true,
        "title": "Hvidvask dokumentation",
        "info" : "Dokumentation af hvidvask af erhvervskunder. Sikker databehandling."
      }
      ]
    }
  },
  created(){
    if (this.view){
      this.currentView = this.view;
    }
    this.$watch(
      () => this.$route.name,
      (toParams, previousParams) => {
        if (this.view){
          this.currentView = this.view;
        }else{
          this.currentView = this.rootView;
        }
      }
    )

    this.$watch(
      () => this.view,
      (toParams, previousParams) => {
        this.currentView = this.view;
      }
    );
    this.$watch(
      () => this.cmsPageData,
      (toParams, previousParams) => {
        this.thisSlug = this.cmsPageData.page.url;
      }
    );
  },
  methods: {
    getFleetUrl: function() {
      let url = 'https://fleetportal.opendo.dk';
      if (window.location.origin.includes("www-test.opendo.dk") || window.location.origin.includes("localhost")) {
        url = 'https://test-fleetportal.opendo.dk/';
      } else if (window.location.origin.includes("www-stage.opendo.dk")) {
        url = 'https://stage-fleetportal.opendo.dk/';
      }

      return url;
    },
    onSubmit: function(e){
      e.preventDefault();

      var formData = new FormData(this.$refs.loginForm);
      this.userName = formData.get('username');
      this.password = formData.get('password');

      if (this.validateSubmit()){
        this.$emit("submit", {"username": this.userName, "password": this.password});
      }

    },
    onFocusUsername(){
      this.userNameErrLabel = "";
    },
    onFocusPassword(){
      this.passwordErrLabel = "";
    },
    resetLabels() {
      this.userNameErrLabel = "";
      this.passwordErrLabel = "";
    },

    validateSubmit() {
      this.resetLabels();
      var validated = true;

      if (this.userName === undefined || this.userName === "") {
        this.userNameErrLabel = "Du mangler at angive brugernavn";
        validated = false;
      }

      if (this.password === undefined || this.password === "") {
        this.passwordErrLabel = "Du mangler at angive kodeord";
        validated = false;
      }

      return validated;
    }
  },

}
</script>
<style lang="scss" scoped>
@import "~@/style.scss";
@import "~@/styles/variables.scss";
  .page-logins{
    padding-bottom: 148px;


    .left-side{
      padding-top: 64px;
      padding-bottom: 64px;
      text-align: center;
      padding-left: 48px;
      padding-right: 48px;
      svg{
        display: inline-block;
        max-width: 100%;
        height: auto;
      }
    }
    .content{
      width:100%;
      max-width: 716px;
      margin-left: auto;
      padding-left: 24px;
      box-sizing: border-box;
      padding-top: 60px;
    }
    h1{
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 72px;
      color: $color-primary;

    }
    h3{
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 130%;
      color: $color-primary;
      opacity: 0.8;
      margin-top: 20px;
    }
    .login-box{
      padding: 40px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 60px;

      .title-wrap{
        position: relative;

        .back-arrow{
          position: absolute;
          top: 0;
          left: 0;
          width: 32px;
          height: 32px;
          line-height: 0;
          .back-arrow-icon{
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -7px;
          }
        }
      }

      .error-label{
        font-size: 12px;
        padding-left: 4px;
        display: block;
        text-align: left;
        color: #DF4343;
        margin-top: 4px;
      }
      .foot-info{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 133%;
        text-align: center;
        color: #4C6C69;
        margin-top: 40px;
      }
      .button{
        border: none;
        margin-top: 34px;
      }
      .form-wrap{
        text-align: center;
        margin-top: 40px;
      }
      .login-field{
        margin-left: auto;
        margin-right: auto;
        display: block;
        max-width: 395px;
        margin-bottom: 16px;
      }

      .title{
        text-align: center;
      }
    }
    .links-list{
      padding: 40px;
      padding-top: 12px;
      padding-bottom: 24px;
      width: 100%;
      box-sizing: border-box;
      margin-top: 60px;
      .title{
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 130%;
        color: $color-primary;
        margin-bottom: 40px;
      }

    }
  }

@media (max-width: 1000px) {
    .page-logins{
      .sides{
        display: block;
      }
      .content{
        max-width: 100%;
        padding-top: 0;
        padding-left: 0;
      }
      h1{
        max-width: 692px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      h3{
        text-align: center;
      }
    }
}
</style>
